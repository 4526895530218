import { FC, memo } from 'react';
import styled from 'styled-components';
import DashboardHeader from 'components/molecules/dashboard-header';
import { getUserRole } from 'app/services/selector-helpers';
import { useAppSelector } from '../../app/store';
import { IDashboard } from 'interfaces/global-interfaces';
import { currentTheme } from '../../configs/local-storage';
import DarkBackground from '../../assets/images/dark_background.png';

const Wrapper = styled.div`
  padding: 0 15px;

  @media screen and (max-width: 992px) {
    padding: 0 10px;
  }
`;

const DashboardWrapper: FC<IDashboard> = ({ routeData, children }) => {
  const role = useAppSelector(getUserRole);

  return (
    <div
      style={
        currentTheme === 'dark'
          ? {
              backgroundImage: `url(${DarkBackground})`,
              minHeight: '100vh',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }
          : { background: '#f4f7fe', minHeight: '100vh' }
      }
    >
      {role && <DashboardHeader role={role} />}
      <Wrapper>{children}</Wrapper>
    </div>
  );
};

export default memo(DashboardWrapper);
