import { currentTheme } from '../configs/local-storage';

export const lightThemeColors = {
  main_color: '#002885',
  border_color: '#5A5A5A',
  cadet_blue: '#ADB5BD',
  yellow_color: '#FFC000',
  destructive: '#f3301a',
  secondary: '#6a6a6b',
  text_colors: {
    yankees_blue: '#232D42',
    davy_grey: '#54595F',
  },
};

export const darkThemeColors = {
  main_color:
    'linear-gradient(126.97deg, rgba(6, 11, 38, 0.74) 28.26%, rgba(26, 31, 55, 0.5) 91.2%)',
  border_color: '#5A5A5A',
  cadet_blue: '#ADB5BD',
  yellow_color: '#FFC000',
  destructive: '#f3301a',
  secondary: '#6a6a6b',
  text_colors: {
    yankees_blue: '#232D42',
    davy_grey: '#54595F',
  },
};

export const colors = currentTheme === 'dark' ? darkThemeColors : lightThemeColors;
