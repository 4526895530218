import styled, { css } from 'styled-components';
import { Row as row, RowProps } from 'antd';

interface IMainRowProps extends RowProps {
  mb?: number;
  ml?: number;
  margin?: string;
  position?: string;
  direction?: string;
  border?: string;
  radius?: string;
  display?: string;
  padding?: string;
  width?: string;
  height?: string;
  min_height?: string;
  flex?: string;
  overflow?: string;
  back_color?: string;
  border_bot?: string;
  border_top?: string;
  cursor?: string;
  odd_back?: string;
  align_self?: string;
  background?: string;
  shadow?: string;
}

const Row = styled(row)<IMainRowProps>`
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    (props.ml || props.ml === 0) &&
    css`
      margin-left: ${props.ml}px!important;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin} !important;
    `}
    ${props =>
    props.position &&
    css`
      position: ${props.position};
    `}
  ${props =>
    props.direction &&
    css`
      flex-direction: ${props.direction};
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.min_height &&
    css`
      min-height: ${props.min_height};
    `}
  ${props =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  ${props =>
    props.align_self &&
    css`
      align-self: ${props.align_self};
    `}
  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
  ${props =>
    props.background &&
    css`
      background: ${props.background};
    `}
  ${props =>
    props.border_bot &&
    css`
      border-bottom: ${props.border_bot};
    `}
  ${props =>
    props.border_top &&
    css`
      border-top: ${props.border_top};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow} !important;
    `}
  ${props =>
    props.odd_back &&
    css`
      &:nth-child(odd) {
        background-color: ${props.odd_back};
      }
    `}
`;

/** @component */
export default Row;
